import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { TokenManagerService } from '../support/service/tokenmanager.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService,
              private tokenManagerService: TokenManagerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err) => {
          if (err.status === 401) {
            this.tokenManagerService.cleanToken();
            const loginRequest = {
                scopes: ["user.read"],
                prompt: "login"  // This will force the user to enter their credentials again
              };
            this.msalService.logout();
            this.msalService.loginRedirect(loginRequest);
          }
        }
      )
    );
  }
}
