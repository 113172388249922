export class MessageConstants {
    public static tableNoData = 'No data available in table';
    public static tableLoading = 'Loading data. Please wait.';
    public static fedNowNetActivityMinValue = 1;
    public static fedNowNetActivityMaxValue = 999999999999;
    public static configuredReturnFeeMinValue = 0;
    public static configuredReturnFeeMaxValue = 50.00;
    public static roleName = 'supportadmin';
    public static resourceName = 'uiToken';
    public static fedNowNetActivityLimitMsg = 'FedNow Net Activity Limit should be in and between $' + MessageConstants.fedNowNetActivityMinValue + ' to $' + MessageConstants.fedNowNetActivityMaxValue;
    public static fedNowNetActivityCountTotalMinValue = 1;
    public static generalError = 'An error occurred. Please try again.' 
    public static dateRangeNotFoundError = 'FromDate and ToDate are required.';
    public static fromDateGreaterLessDateError = 'Invalid date range: FromDate should be less than ToDate.';
    public static liveDateErrorMessage='Do you want to exit this screen without setting a Go Live Date?';
    public static amountLimitErrorMessage='Do you want to exit this screen without setting a Transaction Amount Limit?';
    public static configuredReturnFeeErrorMessage = 'The Return Fee value cannot be blank or empty.';
    public static MaxReturnFeeErrorMessage = 'The maximum value allowed for Return Fee $' + MessageConstants.configuredReturnFeeMaxValue.toFixed(2);
}