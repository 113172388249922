/**
 * Service for managing tokens used for API authentication.
 * @summary Angular service for managing API authentication tokens.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { protectedResources } from 'src/app/auth-config';
import { ProtectedResource } from '../interface/protected.resource.interface';
import { MessageConstants } from 'src/app/common/constants/message.constants';
import { AuthorizationRoles } from 'src/app/common/enum/autorization-roles.enum';

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public token$: Observable<string> = this.tokenSubject.asObservable();

  private refreshInProgress: boolean = false;

  private tokenExpirationTime: number = 0;
  private refreshBufferSeconds: number = 60;

  protected url: string = protectedResources[MessageConstants.resourceName].endpoint;

  constructor(private http: HttpClient) {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000;
      this.tokenExpirationTime = expirationTime - this.refreshBufferSeconds * 1000;
      const now = new Date().getTime();
      const timeToRefresh = this.tokenExpirationTime - now;

      setTimeout(() => {
        this.refreshToken();
      }, timeToRefresh);

      this.tokenSubject.next(token);
    }
  }

  public cleanToken() {
    this.tokenSubject.next('');
    localStorage.removeItem('token');
  }

    /**
   * Retrieves an access token for API authentication.
   * @returns {Observable<string>} An observable of the access token string.
   */
  public getAccessToken(): Observable<string> {
    return this.token$.pipe(
      switchMap(token => {
        if (!token || this.tokenIsExpired()) {
          return this.refreshToken();
        }
        return of(token);
      })
    );
  }

    /**
   * Initiates a token refresh process.
   * @returns {Observable<string>} An observable of the new access token string.
   */
  private refreshToken(): Observable<string> {
    // if (!this.refreshInProgress) {
      this.refreshInProgress = true;
      return this.http.get<string>(this.url)
      .pipe(
        switchMap((awsToken: string) => {
          const newToken = JSON.parse(awsToken).access_token; // get access_token property
          const decodedToken = JSON.parse(atob(newToken.split('.')[1]));
          const expirationTime = decodedToken.exp * 1000;
          this.tokenExpirationTime = expirationTime - this.refreshBufferSeconds * 1000;
          localStorage.setItem('token', newToken);
          this.tokenSubject.next(newToken);
          this.refreshInProgress = false;
          return of(newToken);
        })
      );
    // }
    // else {
    //   return this.token$;
    // }
  }


/**
  Checks if the stored token has expired
  @returns {boolean} true if token has expired, false otherwise
  */
  private tokenIsExpired(): boolean {
    return this.tokenExpirationTime < new Date().getTime();
  }

  /**
   * Checks against the current token whether the current user has the admin role.
   * @returns {Observable<boolean>} True for @enum {AuthorizationRoles.SupportAdmin} users, false otherwise.
   */
  public isAdmin(): Observable<boolean> {
    return this.hasRole(AuthorizationRoles.SupportAdmin, MessageConstants.resourceName);
  }

  /**
   * Checks against the current token whether the current user has the read-only role.
   * @returns {Observable<boolean>} True for @enum {AuthorizationRoles.SupportRead} users, false otherwise.
   */
  public isReadOnly(): Observable<boolean> {
    return this.hasRole(AuthorizationRoles.SupportRead, MessageConstants.resourceName);
  }

    /**
   * Determines if the current token has a specified role.
   * @param {string} roleName - The name of the role to check for.
   * @param {string} [resourceName] - The name of the resource to check against.
   * @returns {Observable<boolean>} An observable of a boolean value indicating whether the token has the specified role.
   */
  public hasRole(roleName: string, resourceName?: string): Observable<boolean> {
    const resources: ProtectedResource[] = resourceName ? [protectedResources[resourceName]] : Object.values(protectedResources);

    return this.token$.pipe(
      switchMap(token => {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        let tokenRoles: string[];
        if (Array.isArray(decodedToken.role)) {
          tokenRoles = decodedToken.role;
        } else {
          tokenRoles = [decodedToken.role];
        }
        for (const resource of resources) {
          for (const role in resource.roles) {
            if (role === roleName.toLocaleLowerCase()) {
              const requiredRoles = resource.roles[role];
              const hasRequiredRoles = requiredRoles.every(role => tokenRoles.includes(role));
              if (hasRequiredRoles) {
                return of(true);
              }
            }
          }
        }
        return of(false);
      })
    );
  }
}
