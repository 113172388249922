<div class="layout-container" [ngClass]="containerClass">

    <app-topbar (logoutClick)="logOut()"></app-topbar>
    <!-- <app-rightmenu></app-rightmenu> -->
    <app-sidebar></app-sidebar>

    <div class="layout-content-wrapper">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <p-toast position="bottom-right"></p-toast>
    <div *ngIf="layoutService.state.staticMenuMobileActive" class="layout-mask"></div>
</div>