import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalInterceptor } from '@azure/msal-angular'; // Import the original MsalInterceptor

/**
 * Custom HTTP interceptor that delegates requests to an MsalInterceptor and removes a custom header.
 * @summary Delegates requests to an MsalInterceptor and removes a custom header before processing.
 * @param {MsalInterceptor} msalInterceptor - The MsalInterceptor to delegate requests to.
 */
@Injectable()
export class CustomMsalInterceptor implements HttpInterceptor {
  constructor(private msalInterceptor: MsalInterceptor) {}

  /**
   * Intercepts an HTTP request and delegates it to an MsalInterceptor, removing a custom header before processing.
   * @param {HttpRequest<any>} req - The HTTP request to intercept.
   * @param {HttpHandler} next - The next HTTP handler in the chain.
   * @returns {Observable<HttpEvent<any>>} An observable of the HTTP event.
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check for the custom header and bypass processing if it's present
    if (req.headers.has('x-handled-by-aws')) {
      // Remove the custom header before sending the request
      const clonedRequest = req.clone({ headers: req.headers.delete('x-handled-by-aws') });
      return next.handle(clonedRequest);
    }

    // Delegate the request to the original MsalInterceptor
    return this.msalInterceptor.intercept(req, next);
  }
}

