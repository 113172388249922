
<div class="layout-topbar">
    <div class="layout-topbar-start">
        <a class="layout-topbar-logo" routerLink="/">
            <img class="layout-topbar-logo-full" src="../../assets/AptysSolutionsLogo.png" title="Aptys Solutions"
                style="width: 80%; height: 80%; object-fit: contain;" />
        </a>
        <a #menuButton class="layout-menu-button" (click)="onMenuButtonClick()" pRipple>
            <i class="pi "
                [ngClass]="layoutService.state.staticMenuDesktopInactive ? 'pi-chevron-left' : 'pi-chevron-right' "></i>
        </a>

        <a #mobileMenuButton class="layout-topbar-mobile-button" (click)="onMobileTopbarMenuButtonClick()" pRipple>
            <i class="pi pi-ellipsis-v"></i>
        </a>
    </div>

    <div class="layout-topbar-end">
        <div class="layout-topbar-actions-start">
        </div>
        <div class="layout-topbar-actions-end">
            <ul class="layout-topbar-items">
                <p class="center logged-in-user"> {{loggedInUser}} <span *ngIf="isAdmin"> (Admin)</span></p>
                <li>
                    <a pStyleClass="@next" enterClass="ng-hidden" enterActiveClass="px-scalein" leaveToClass="ng-hidden"
                        leaveActiveClass="px-fadeout" [hideOnOutsideClick]="true" pRipple>
                        <img src="assets/images/userprofile.png" alt="profile" class="w-2rem h-2rem">
                    </a>
                    <div class="ng-hidden">
                        <ul class="list-none p-0 m-0">
                            <li>
                                <a class="cursor-pointer flex align-items-center py-2 hover:surface-hover transition-duration-150 transition-all px-3 py-2"
                                    pRipple>
                                    <!-- <i class="pi pi-cog mr-2"></i> -->
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer flex align-items-center py-2 hover:surface-hover transition-duration-150 transition-all px-3 py-2"
                                    pRipple>
                                    <!-- <i class="pi pi-compass mr-2"></i> -->
                                    <span>Activity Log</span>
                                </a>
                            </li>
                            <hr />
                            <li>
                                <a href="#"
                                    (click)="onLogoutClick()"
                                    class="cursor-pointer flex align-items-center py-2 hover:surface-hover transition-duration-150 transition-all px-3 py-2"
                                    pRipple>
                                    <!-- <i class="pi pi-power-off mr-2"></i> -->
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <li></li>
            </ul>
        </div>
    </div>
</div>
